import React from 'react'

import Layout from '../components/Layout'
import Flex from '../components/Flex'
import Box from '../components/Box'
import Seo from '../components/Seo'
import Container from '../components/Container'
import Jumbotron from '../components/Jumbotron'
import Section from '../components/Section'
import ResponsiveImage from '../components/ResponsiveImage'

import helloTVW from '../images/screenshots/tvw.png'
import wijkpaspoortScreen from '../images/screenshots/wijkpaspoort.png'
import energieScreen from '../images/screenshots/energie.png'
import tellenScreen from '../images/screenshots/grafiek.gif'
import drukteScreen from '../images/screenshots/drukte.png'
import metrics from '../images/screenshots/metrics.png'
import dookScreen from '../images/screenshots/dook.png'

const IndexPage = () => (
  <Layout>
    <Seo />
    <Jumbotron>
      <h1>Common Data Factory</h1>

      <p>Verwerken van data tot kaarten, API's en opgeschoonde datasets voor het uitvoeren en maken van plannen</p>

    </Jumbotron>
    <Container>
      <Section>
        <Flex scrollable>
          <Box width={1/3}>
            <h2>Ontsluiten van data</h2>
            <p> Gebruiksvriendelijke kaarten</p>
            <p> APIs voor datasets </p>
            <p> QGIS projecten voorbereid </p>
            <p> Ruwe data van elke dataset zijn beschikbaar voor eigen verwerking</p>
            <p> Code gepubliceert op gitlab</p>
            <p> Elk kennisniveau is er aanbod.</p>
          </Box>
          <Box width={1/3}>
            <h2>Herbruikbaar</h2>
            <p> Zowel de referentie implementatie als de additionele tools zijn beschikbaar als open source software </p>
            <p> Toetsbaar</p>
            <p> Transparant </p>
            <p> Overheden, leveranciers en data scientists kunnen hun eigen aanpassingen doen en oplossingen toetsen</p>
            <p> De infrastructuur van <a href="https://haven.commonground.nl">Haven</a> </p>
          </Box>
          <Box width={1/3}>
            <h2> Secure by default </h2>
            <p> Data die niet open is wordt veilig ontsloten.</p>
            <p> <a href="https://Ory.sh"> Ory.sh </a> als opensource en universele authorisatie en identificatie oplossing </p>
	    <p> Kan aangesloten worden op andere Identity Providers </p>
          </Box>
        </Flex>
      </Section>

      <hr />

      <Section>
        <Flex>
          <Box width={2/5}>
            <h2>DOOK.vng.nl </h2>
            <h3> Onregelmatigheden op de kaart </h3>
            <p>
	    	          <a href="https://dook.vng.nl/"> publieke versie dook.vng.nl </a>
            </p>
            <ul>
              <li>KVK </li>
              <li>CBS buurt gegevens</li>
              <li>Download excel met data voor onderzoek</li>
            </ul>
          </Box>
          <Box width={3/5}>
            <a href="https://dook.vng.nl/">
            <ResponsiveImage src={dookScreen} alt="Schermafbeelding van de dook" />
	    </a>
          </Box>
        </Flex>
      </Section>



      <Section>
        <Flex>
          <Box width={2/5}>
            <h2>Wijkpaspoort</h2> <h3> Energie Transitie </h3>
            <p>
              Hulpmiddelen om een transitievisie warmte plan te maken zijn direct beschikbaar:
		          <a href="https://wijkpaspoort.vng.nl/"> wijkpaspoort.vng.nl </a>
		          De focus ligt hierbij om onderscheid te kunnen maken tussen wijken.
	          </p>
            <ul>
              <li>Kleinverbruik data 2019</li>
              <li>CBS buurt gegevens</li>
              <li>Eigendom verdeling Kadaster</li>
              <li>Eenvoudige link van buurt naar dego.vng.nl</li>
              <li>Download excel met bekeken data</li>
              <li>Bouwjaar verdeling</li>
              <li>Isolatie / label indicaties</li>
            </ul>
          </Box>
          <Box width={3/5}>
            <a href="https://wijkpaspoort.vng.nl/">
            <ResponsiveImage src={wijkpaspoortScreen} alt="Schermafbeelding van de wijkpaspoort" />
	    </a>
          </Box>
        </Flex>
      </Section>

      <Section>
        <Flex>
          <Box width={2/5}>
            <h2> DEGO </h2>
            <h3>Data Energie Gebouwde Omgeving</h3>
            <h3><a href="https://dego.vng.nl">dego.vng.nl</a></h3>

            <p>
              Hulpmiddelen om een transitievisie warmte plan te maken zijn direct beschikbaar:
	      <a href="https://dego.vng.nl/?layer=layer21&year=&tab=energie#16.52/52.088133/4.311561/12.8/38"> dego.vng.nl </a>
	      Het is nu mogelijk om tellingen te doen op eigen selecties en de data daarachter te downloaden.
	     </p>
            <ul>
              <li>actulele Kleinverbruik data </li>
              <li>CBS buurt gegevens</li>
              <li>Postcode 6 gebieden</li>
              <li>Energie klasses</li>
              <li>Aanwezigheid gas aansluiting</li>
              <li>Gebouw gegevens</li>
              <li>Bouwjaar</li>
              <li>Isolatie indicaties</li>
              <li>Aansluitings dichtheid</li>
            </ul>
          </Box>
          <Box width={3/5}>
            <a href="https://dego.vng.nl/?layer=layer21&year=&tab=energie#16.52/52.088133/4.311561/12.8/38">
            <ResponsiveImage src={helloTVW} alt="Schermafbeelding van de DEGO" />
	    </a>
          </Box>
        </Flex>
      </Section>

      <Section>
        <Flex>
          <Box width={2/5}>
            <h2> DEGO.vng.nl 2019 </h2>
            <h3> Data Energie gebouwde Omgeving </h3>
            <p>
              Met de energie.commondatafactory.nl KON dieper in gegaan worden op de historische energiedata om trends en vooruitgang te monitoren.
	      LETOP de ontwikkelingen hiervan staan op pauze en <a href="https://dego.vng.nl/">dego.vng.nl</a> is het meest actueel.
            </p>
            <ul>
              <li>Kleinverbruik data 2014 tot nu</li>
              <li>Gedetaileerde gegevens</li>
              <li>CBS buurt gegevens</li>
              <li>Postcode 6 gebieden</li>
              <li>Gebouw gegevens</li>
              <li>Bouwjaar</li>
              <li>Isolatie indicaties</li>
              <li>Aansluitings dichtheid</li>
            </ul>

          </Box>
          <Box width={3/5}>
            <ResponsiveImage src={energieScreen} alt="Schermafbeelding van Energie data viewer" />
          </Box>
        </Flex>
      </Section>

      <Section>
        <Flex>
          <Box width={2/5}>
            <h2> API datavoorziening </h2>
            <h3> Bulk Downloads </h3>
            <p>
              Alle gebruikte data kunt u inladen in een willekeurig GIS-programma om het te combineren met uw eigen data,
	      en meer geavanceerde verwerkingen toe te passen.
            </p>
	    <ul>
              <li>De OpenStreetMap-kaart van Nederland</li>
              <li>Administratieve grenzen</li>
              <li>Ingeladen datasets vanuit de <a href="https://commondatafactory.nl/docs/API">eigen API</a></li>
              <li>Toegang tot <a href="https://commondatafactory.nl/docs/databron">alle data als geopackage </a> uit onze database</li>
	      <li>Een kant en klaar QGIS voorbeeld project <a href="/docs/gis"> Uitleg </a> </li>
            </ul>

          </Box>
          <Box width={3/5}>
           <a href="">
            <ResponsiveImage src={tellenScreen} alt="Schermafbeelding van het QGIS project" />
	   </a>
          </Box>
        </Flex>
      </Section>


      <Section>
        <Flex>
          <Box width={2/5}>
            <h2>Drukte data (Offline) </h2>
            <p>
              Met de drukte.commondatafactory.nl (offline).
	            is er een beproefing bezig samen met CTO office van Amsterdam omtrent het verzamelen van drukte data in verband met handhaving van 1.5 meter corona samenleving.
	            was een experiment in de begin tijd van covid19 uitbraak.
            </p>
	    <ul>
              <li>Anonieme populariteits data</li>
            </ul>
	    <p>
		De test is nu afgelopen. Er zijn nu genoeg
	        alternatieven op google data.
	    </p>

          </Box>
          <Box width={3/5}>
              <ResponsiveImage src={drukteScreen} alt="Schermafbeelding van de Drukte dashboard" />
          </Box>
        </Flex>
      </Section>

      <Section>
        <Flex>
          <Box width={2/5}>
            <h2>Inzicht in data</h2>
            <p>
              Common Data Factory biedt inzicht in data en de verwerkingen die we gedaan hebben op een GDPR verantwoorde manier.
            </p>
          </Box>
          <Box width={3/5}>
            <ResponsiveImage src={metrics} alt="Schermafbeelding van grafieken die de prestatie van een cluster tonen" />
          </Box>
        </Flex>
      </Section>
    </Container>
  </Layout>
)

export default IndexPage
